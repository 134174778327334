<template>
  <div>
    <b-card border-variant="danger" v-if="errore_caricamento">
      <b-row class="match-height">
        <b-col
          md="8"
        >
          <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
          <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
        </b-col>
        <b-col 
          md="4"
          class="text-center"
        >
          <b-button
          variant="primary"
          :to="{ name: 'crm-pagamenti-index' }"
          >
              <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                  class="mr-1"
              />Torna ai Pagamenti
          </b-button>
        </b-col>
      </b-row>
        
    </b-card>
    
    <div class="text-center my-3" v-if="Caricato == false">
        <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem;"
            class="mr-1"
            label="loading"
        />
    </div>

    <div v-if="Caricato">
        <b-card :border-variant="border_color">
          <b-card-header class="pt-0">
              <b-card-title :class="title_color">
                {{ dati.license.ref_code }} - {{ dati.license.customer.billing.company_name }}
              </b-card-title>
          </b-card-header>

          <b-card-body class="px-0 py-0">
              <b-tabs pills>
                <b-tab active>
                  <template #title>
                      <feather-icon
                      icon="UserIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                      />
                      <span class="d-none d-sm-inline">Pagamento e Licenza</span>
                  </template>
                  <b-card bg-variant="Default">
                      <b-row>
                        <b-col md="4" lg="3">
                            <dl>
                                <dt class="text-muted">
                                    Stato
                                </dt>
                                <dd>
                                    <strong v-if="dati.paid" class="text-primary">Pagato</strong>
                                    <strong v-else class="text-warning">Da pagare</strong>
                                </dd>
                            </dl>
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Importo" :field_value="prezzoFormattato(dati.license.price)" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Licenza" :field_value="dati.license.ref_code" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Tipologia" :field_value="dati.license.type" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Creato il" :field_value="formattazioneDataOra(dati.created_at, 'it')" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Aggiornato al" :field_value="formattazioneDataOra(dati.updated_at, 'it')" />
                        </b-col>
                      </b-row>

                      <div v-if="dati.paid">
                        <h5 class="mt-3 mb-1 pb-1 border-bottom">Dati del pagamento su Stripe</h5>
                        <b-row>
                            <b-col md="6" lg="4">
                                <dl>
                                    <dt class="text-muted">ID invoice</dt>
                                    <dd>{{ dati.api_invoice }} <a :href="dati.invoice_link" title="vai alla Dashboard di Stripe" target="_blank"><feather-icon icon="ExternalLinkIcon" size="16" /></a></dd>
                                </dl>
                            </b-col>
                            <b-col md="6" lg="4">
                            <showdata field_name="payment_intent" :field_value="dati.api_payment_intent" />
                            </b-col>
                        </b-row>
                      </div>
                      <div v-else class="mt-3">
                        <h5 class="mt-3 mb-1 pb-1 border-bottom">Come far provvedere il cliente al pagamento</h5>
                        link per poter inviare al cliente per farlo pagare (che trova nella sua "Area Cliente")
                        http://localhost:8080/area-clienti/licenze/show/{{ dati.license.id }}
                      </div>

                    </b-card>
                  </b-tab>

                  <b-tab v-if="dati.dispute">
                    <template #title>
                        <feather-icon
                        icon="LockIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Disputa</span>
                    </template>
                    <b-card bg-variant="Default">
                      <b-row>
                        <b-col cols="12">
                          <showdata field_name="Stato:" :field_value="dati.dispute.dispute_type.name" />
                        </b-col>
                        <b-col md="4" lg="3">
                          <showdata field_name="Creato il" :field_value="formattazioneDataOra(dati.dispute.api_event_created_at, 'it')" />
                        </b-col>
                        <b-col md="6" lg="4">
                          <showdata field_name="payment_intent" :field_value="dati.dispute.api_payment_intent" />
                        </b-col>
                        
                      </b-row>
                    </b-card>
                  </b-tab>

                  <b-tab v-if="dati.paid">
                    <template #title>
                        <feather-icon
                        icon="LockIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Rimborso</span>
                    </template>
                    <b-card bg-variant="Default">
                        <div v-if="dati.refund === null">
                          <b-alert variant="warning" show>
                            <h4 class="alert-heading">
                              E' possibile effettuare il rimborso per questo pagamento
                            </h4>
                            <div class="alert-body">
                              <p class="pb-2">Nota bene: il rimborso sarà effettuato tramite Stripe per l'intero importo della trasazione ({{ prezzoFormattato(dati.license.price) }})</p>
                              <p class="pb-2">
                                <b-button
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  variant="danger"
                                  class="mb-1"
                                  @click="faiRimborso(dati.id,prezzoFormattato(dati.license.price))"
                                >
                                  Invio rimborso
                                </b-button>
                            </p>
                            </div>
                          </b-alert>
                        </div>
                        <div v-else>
                          <showdata field_name="Stato del rimborso" :field_value="dati.refund" />
                          <hr />
                          <pre>{{ dati.refund }}</pre>
                        </div>

                    </b-card>
                  </b-tab>

                  <b-tab>
                    <template #title>
                        <feather-icon
                        icon="LockIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Cliente</span>
                    </template>
                    <b-card bg-variant="Default">
                        <b-row>
                            <b-col cols="12">
                                <showdata field_name="Ragione Sociale" :field_value="dati.license.customer.billing.company_name" />
                            </b-col>
                            <b-col md="4" lg="3">
                                <showdata field_name="Indirizzo" :field_value="dati.license.customer.billing.address" />
                            </b-col>
                            <b-col md="4" lg="3">
                                <showdata field_name="Cap" :field_value="dati.license.customer.billing.zip" />
                            </b-col>
                            <b-col md="4" lg="3">
                                <showdata field_name="Città" :field_value="dati.license.customer.billing.city" />
                            </b-col>
                            <b-col md="4" lg="3">
                                <showdata field_name="Provincia" :field_value="dati.license.customer.billing.province_name" />
                            </b-col>
                            <b-col md="4" lg="3">
                                <showdata field_name="Partita IVA" :field_value="dati.license.customer.billing.vat_number" />
                            </b-col>
                            <b-col md="4" lg="3">
                                <showdata field_name="Codice fiscale" :field_value="dati.license.customer.billing.fiscal_code" />
                            </b-col>
                            <b-col md="4" lg="3">
                                <showdata field_name="Pec" :field_value="dati.license.customer.billing.pec" />
                            </b-col>
                            <b-col md="4" lg="3">
                                <showdata field_name="Codice SDI" :field_value="dati.license.customer.billing.dest_code" />
                            </b-col>
                            <b-col md="4" lg="3">
                                <showdata field_name="Convertito in cliente il" :field_value="formattazioneDataOra(dati.license.customer.converted_at, 'it')" />
                            </b-col>
                        </b-row>

                        <hr />

                        <b-row>
                            <b-col md="4" lg="3">
                                <showdata field_name="Nome" :field_value="dati.license.customer.user.name" />
                            </b-col>
                            <b-col md="8" lg="9">
                                <showdata field_name="Cognome" :field_value="dati.license.customer.user.surname" />
                            </b-col>
                            <b-col md="4" lg="6">
                                <showdata field_name="Email" :field_value="dati.license.customer.user.email" />
                            </b-col>
                            <b-col md="4" lg="3">
                                <showdata field_name="Telefono" :field_value="dati.license.customer.user.phone" />
                            </b-col>
                            <b-col md="4" lg="3">
                                <showdata field_name="Cellulare" :field_value="dati.license.customer.user.mobile_phone" />
                            </b-col>
                            <b-col md="4" lg="3">
                                <showdata field_name="Registrazione il" :field_value="formattazioneDataOra(dati.license.customer.created_at, 'it')" />
                            </b-col>
                            <b-col cols="12">
                                <showdata field_name="Note" :field_value="dati.license.customer.user.note" />
                            </b-col>
                        </b-row>

                    </b-card>
                  </b-tab>

                  <b-tab>
                    <template #title>
                      <feather-icon
                        icon="ClipboardIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span class="d-none d-sm-inline">Lead</span>
                    </template>
                    <b-card bg-variant="Default">
                        <b-row>
                            <b-col md="8" lg="9">
                                <showdata field_name="Sorgente" :field_value="dati.license.customer.source.text" />
                            </b-col>
                            <b-col md="4" lg="3">
                                <showdata field_name="Del" :field_value="formattazioneDataOra(dati.license.customer.lead_registered_at, 'it')" />
                            </b-col>
                        </b-row>
                        
                    </b-card>
                  </b-tab>

              </b-tabs>
          </b-card-body>
        </b-card>
    </div>
      
  </div>
</template>

<script>
import router from '@/router'
import { BTabs, BTab, BRow, BCol, BCard, BCardTitle, BCardHeader, BCardBody, BCardText, BSpinner, BButton, VBToggle, BBadge, VBTooltip, BAlert } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import showdata from '@core/components/showdata/showdata.vue'
import { prezzoFormattato } from '@core/utils/funzioniGlobali' 
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { logoutGeneral } from "@core/components/logout/logout.vue"

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard, 
    BCardTitle, 
    BCardHeader, 
    BCardBody, 
    BCardText, 
    BSpinner,
    BButton, 
    BBadge,
    BAlert,

    showdata,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent, 
    logoutGeneral,
  },
  setup() {
    return {
      prezzoFormattato,
    }
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      dati: { },
      Caricato: false,
      errore_caricamento: false,
      title_color: 'text-primary',
      border_color: 'primary',
      
    }
  },
  created() {
    this.caricamentoDatiPagamento(router.currentRoute.params.id_riga);

  },
  methods: {
    caricamentoDatiPagamento(id_riga_passata){
      //imposto loading della pagina
      this.Caricato = false;

      this.$http.get('api/crm/license-payment-logs/card/'+id_riga_passata).then(response => { 
        if(response.status===200){
          //ok dati ricevuti
          // check autorizzazione ad accedere ai dati oppure no
          if(response.data.statusCode===200){
            //ok autorizzato ! => proseguo
            this.dati = response.data.reply.licensePaymentLogData

            //console.log("FINE --------------------")
            //console.log(this.dati)

            if(this.dati.paid === true){
                this.title_color = 'text-primary';
                this.border_color = 'primary';
            } else {
                //non pagato
                this.title_color = 'text-warning';
                this.border_color = 'warning';
            }

            this.Caricato = true;
          } else {
            //accesso a pagamento che non è il tuo! => accesso "Unauthenticated" 
            this.Caricato = false;
            this.errore_caricamento = true;
          }
        } else {
          if(response.status===401){
            //accesso "Unauthenticated" -> accade quando si sviene sloggati dall'api
            console.error("Errore api -> "+response.data.reply['message'])

            if(logoutGeneral() === true){
              // Redirect to login page
              //this.$router.push({ name: 'auth-login' })
              this.$router.replace('/login?status=again')
            }
          }

          this.Caricato = false;
          this.errore_caricamento = true;
        }
      
      }).catch(e => {
        console.log("Errore api ......")
        console.log(e)

        this.Caricato = false;
        this.errore_caricamento = true;

      });
    },
    faiRimborso(id_riga,importo){
      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "Vuoi confermare il rimborso al cliente su Stripe di "+importo+" ?",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.post('api/crm/license-payment-logs/refund/'+id_riga)
            .then(response => { 
                console.log("risposta server --------")
                console.log(response.data)

                if(response.data.statusCode===200){
                  //risposta positiva
                  this.$swal({
                    icon: 'success',
                    title: 'Richiesta di rimborso presa in carico da Stripe',
                    text: 'Il riborso avverrà nel tempi e nelle modalità previste da Stripe, puoi controllare i dettagli del pannello su Stripe',
                    confirmButtonText: 'chiudi',
                    customClass: {
                    confirmButton: 'btn btn-success',
                    },
                  });
                  
                  //ricarico i dati
                  this.caricamentoDatiPagamento(id_riga)
                } else {
                //risposta negativa (errore sul server)
                  this.$swal({
                    icon: 'error',
                    title: 'Si è verificato un errore',
                    text: 'operazione non riuscita, ti invitamo a verificare lo stato del pagamento su Stripe e ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                    customClass: {
                    confirmButton: 'btn btn-outline-primary',
                    },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },
    formattazioneDataOra(created_at,lang) {
      if (created_at) {
        return moment(String(created_at)).locale(lang).format('DD/MM/YYYY - HH:mm:ss')
      } else {
        return '---'
      }
    },
    
  },
}
</script>